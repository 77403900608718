<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="title-3">API Keys ({{ list.length }})</h3>
      <b-button
        variant="success"
        @click="isModalOpen = true"
      >
        Add API Key
      </b-button>
    </div>
    <b-table
      :items="list"
      :fields="fields"
      hover
      responsive
      class="app-table"
    >
      <template #cell(index)="{ index }">
        {{ index + 1 }}
      </template>
    </b-table>
    <b-modal
      v-model="isModalOpen"
      title="Add API Key"
      centered
      scrollable
      title-class="font-22"
      hide-footer
      @hide="onModalHide"
    >
      <multiselect
        v-model="newApiKey"
        :options="apiKeys"
        searchable
        track-by="id"
        label="api_key"
        class="app-multiselect"
      >
        <template #option="{ option }">
          {{ option.api_key }} | {{ option.service.title }}
        </template>
      </multiselect>
      <div class="d-flex justify-content-end mt-4">
        <b-button
          variant="success"
          :disabled="!newApiKey"
          @click="handleAddEmployeeToKey"
        >
          Add
        </b-button>
      </div>
      <loader :show="loading" position="absolute" small />
    </b-modal>
  </b-card>
</template>

<script>
import Loader from '@/components/Loader.vue';

export default {
  components: { Loader },
  props: {
    list: {
      type: Array,
    },
    employeeUid: String
  },
  data() {
    return {
      loading: false,
      newApiKey: null,
      isModalOpen: false,
      fields: [
        { key: 'service.title', label: 'Service Name', thStyle: { minWidth: '140px' } },
        { key: 'api_key', label: 'API Key' },
        { key: 'description', label: 'Description' },
        { key: 'creation_date', label: 'Creation Date', thStyle: { minWidth: '140px', width: '140px', maxWidth: '140px' } },
        { key: 'expiration_date', label: 'Expiration Date', thStyle: { minWidth: '140px', width: '140px', maxWidth: '140px' } }
      ],
    }
  },
  mounted() {
    this.$store.dispatch('apiKeys/getApiKeys');
  },
  methods: {
    onModalHide() {
      this.newApiKey = null;
    },
    async handleAddEmployeeToKey() {
      this.loading = true;
      this.$store.dispatch('apiKeys/updateApiKey', {
        id: this.newApiKey.id,
        data: {
          employees: [this.employeeUid]
        }
      })
        .then(() => {
          this.isModalOpen = false;
          this.$emit('on-add-success');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    apiKeys() {
      return this.$store.state.apiKeys.list
        .filter(key => !this.list.some(employeeKey => key.id === employeeKey.id));
    }
  }
}
</script>

<style scoped>
.app-table {
  width: calc(100% + 40px);
  margin: 20px -20px 0px -20px;
}
:deep(.modal-body) {
  position: static;
}
</style>
